<div class="ui-timed-image">
		{{#if isRecentlyAdded}}
			<div class="ribbon-wrapper"><div class="ribbon-trh">{{{recentlyAddedMessage}}}</div></div>
		{{/if}}
		{{#if firstImage.originalFilename}}
			<a rel="prettyPhoto" title="{{{lotTitle}}}" href="{{#cloudify firstImage lbDims}}{{/cloudify}}" alt="{{{firstImage.originalFilename}}}">
		{{/if}}
	    <div class="text-center">
	        {{#if multiPage}}
	        	<a id="itemDetails{{id}}image" class="evh_LoadItemDetails img-responsive" data-id="{{id}}" href="{{contextPath}}/auctions/{{auctionUuid}}/listings/{{listingUuid}}">
	        {{/if}}
	        {{#if firstImage}}
				<img id="biddingItemImage_{{id}}" class="x-timed-preview img-responsive" onerror="myApp.utils.errors.ImgErrorPreview(this)" src="{{#cloudify firstImage dims}}{{/cloudify}}" alt="{{{firstImage.originalFilename}}}" />
			{{else}}
	            <span class="img-error-list-home" ><br/>{{{awaitingImageMessage}}}</span>
	        {{/if}}
	        {{#if multiPage}}
	        	</a>
	        {{/if}}
	    </div>
		{{#if firstImage.originalFilename}}
			</a>
		{{/if}}
</div>
