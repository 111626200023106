
<div class="container">
	<div id="landingBannerRegion"></div>
	<div class="row">
		<div id="landingSearchInputRegion" class="col-md-12"></div>
	</div>
	<div id="layoutControlRegion" class="row"></div>
	<div class="row">
		{{#if showFilters}}
			<div id="searchFiltersRegion" class="col-xs-12 col-md-3"></div>
			<div class="col-xs-12 col-md-9">
				<div class="row">
					<div class="col-xs-12">
						<div id="pagingTopRegion" class="paging pull-right"></div>
					</div>
				</div>
				<div class="row">
					<div id="biddingRegion"></div>
				</div>
			</div>
		{{else}}
		<div class="col-xs-12">
			<div class="row">
				<div class="col-xs-12">
					<div id="pagingTopRegion" class="paging pull-right"></div>
				</div>
			</div>
			<div class="row">
				<div id="biddingRegion"></div>
			</div>
		</div>
		{{/if}}
	</div>
	<div class="row">
		<div id="pagingBottomRegion" class="col-xs-12"></div>
	</div>
</div>
