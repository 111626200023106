{{#if isVisible}}
	{{#if isGallery}}
		<a id="itemDetailsImageLink{{model.id}}" href="{{contextPath}}/auctions/{{model.auctionUuid}}/listings/{{model.listingUuid}}">
	{{else}}
		<a rel="prettyPhoto" id="itemDetailsImageLink{{model.id}}" title="{{{model.localeSensitive.title}}}" href="{{#cloudify model.firstImage lightboxImageDimensions}}{{/cloudify}}">
	{{/if}}
		{{#if model.firstImage.attachmentUrl}}
			<img class="grid-item--thumbnail" src="{{#cloudify model.firstImage imageDimensions}}{{/cloudify}}" alt="{{{model.localeSensitive.title}}}">
		{{else}}
			<div class="grid-item--thumbnail img-error-gal-home">{{{messages.awaitingImage}}}</div>
		{{/if}}
	</a>
	<div class="caption grid-item--content grid-content">
		<div class="grid-content--labels">
			<div class="label label-primary">
				{{#if hasFlags}}
					<img class="x-flag mini-roundel-flag" title="{{{model.localeSensitive.countryName}}}" alt="{{{model.localeSensitive.countryName}}}" src="{{staticPath}}/images/flags/{{{model.localeSensitive.countryCode}}}.png" />
				{{/if}}

				{{#if hasItemRoundels}}
					<span class="mini-roundel">
						{{#if model.itemType.marketplace}}
							<i class="fa fa-medium"></i>
						{{else if model.itemType.webcasty}}
							<i class="fa fa-wifi"></i>
						{{else}}
							<i class="fa fa-clock-o"></i>
						{{/if}}
					</span>
				{{/if}}
				{{{numberOrRef}}}
			</div>
			{{#if model.recentlyAdded}}
				<div class="label label-info">
					{{{recentlyAddedMessage}}}
				</div>
			{{/if}}
		</div>
		{{#if model.itemType.catalogue}}
			<div id="grid-content--message-{{model.id}}" class="grid-content--actions grid-item--grow grid-item--no-shrink text-center">
				<p class="help-block"><small>{{{messages.catalogueItem}}}</small></p>
				{{#if externalWebpageUrl}}
					{{#unless isArchived}}
						<div class="btn-group btn-group-justified" role="group" aria-label="actions">
							<div class="btn-group" role="group">
								<a id="gotoExternalCatalogue{{model.id}}" href="{{{externalWebpageUrl}}}" target="_blank" rel="noopener noreferrer">
									<button id="viewSimilarItems{{model.id}}" class="btn btn-primary" type="button">
										{{{messages.catalogueLink}}}
										<i class="fa fa-external-link" aria-hidden="true"></i>
									</button>
								</a>
							</div>
						</div>
					{{/unless}}
				{{/if}}
			</div>
		{{else}}
			<div id="grid-content--heading-{{model.id}}" class="grid-content--info grid-item--grow grid-item--no-shrink">
				<p id="timeLeftContainer{{model.id}}" {{#unless messages.timeLeft}}class="hidden"{{/unless}}><strong id="timeLeft{{model.id}}">{{{messages.timeLeft}}}</strong></p>
				<a id="itemsDetailsTitleLink{{model.id}}" href="{{contextPath}}/auctions/{{model.auctionUuid}}/listings/{{model.listingUuid}}">
					<h4>{{{model.localeSensitive.title}}}</h4>
				</a>
				{{#if model.localeSensitive.summary}}
					<p id="lotSummary{{model.id}}"><em>{{{model.localeSensitive.summary}}}</em></p>
				{{/if}}
			</div>
			<div id="grid-content--bidding-{{model.id}}" class="grid-item--bidding grid-item--no-grow grid-item--no-shrink text-center">
				{{#if bidding.hasWinningBidAmount}}
					<h5 id="highestBidMessage{{model.id}}">
						{{{messages.bidMessage}}}: <strong> <span id="highestBid{{model.id}}">{{{displayPrice}}}</span></strong>
					</h5>
					<span id="highBidAmountCalculation{{model.id}}">{{{bidding.highBidAmountCalculation}}}</span>
				{{else if hasDisplayPrice}}
					<h5 id="displayPriceMessage{{model.id}}">
						{{{messages.displayPrice}}}: <strong><span id="displayPrice{{model.id}}">{{{displayPriceWithCurrency}}}</span></strong>
					</h5>
				{{/if}}

				{{#if bidding.hasReserveIndicator}}
					<div class="progress">
						<div
							id="progress-bar--{{model.id}}"
							class="progress-bar progress-bar-success progress-bar-striped active"
							role="progressbar"
							aria-valuenow="{{bidding.percentOfReserve}}"
							aria-valuemin="0"
							aria-valuemax="100"
							data-animate-width="{{bidding.percentOfReserve}}%"
							style="width: {{bidding.percentOfReserve}}%;"
						>
							<span class="sr-only">{{bidding.percentOfReserve}}% of Reserve</span>
						</div>
					</div>
				{{/if}}

				{{#if bidding.hasIncrements}}
					<h6 id="bidIncrementMessage{{model.id}}">
						{{{messages.bidIncrement}}}: <span id="increment{{model.id}}">{{{bidding.incrementWithCurrency}}}</span>
					</h6>
				{{/if}}

				{{#if bidding.hasCount}}
					<h6 id="numberOfBidsMessage{{model.id}}">
						{{{messages.bidCount}}}: <span id="numberOfBids{{model.id}}">{{{bidding.count}}}</span>
					</h6>
				{{/if}}

				{{#if hasNumberOfUnits}}
					<h6 id="numberOfUnitsMessage{{model.id}}">{{{totalUnits}}}</h6>
				{{/if}}

				{{#if messages.bidType}}
					<h6 id="bidTypeMessage{{model.id}}">{{{messages.bidType}}}</h6>
				{{/if}}

				{{#if model.itemType.bulkBuyItem}}
					<div class="row">
						<div class="col-xs-12 col-md-6 text-center">
							<small id="numberOfUnits{{model.id}}">{{{totalUnits}}}</small>
						</div>
						<div class="col-xs-12 col-md-6 text-center">
							<small id="numberOfUnitsRemaining{{model.id}}">{{{remainingUnits}}}</small>
						</div>
					</div>
				{{/if}}
			</div>
			<div id="grid-content--message-{{model.id}}" class="grid-item--messages text-center grid-item--no-shrink">
				<div class="alert {{messages.statusSeverity}} margin-top margin-bottom--none">
					{{{messages.userStatus}}}
				</div>
			</div>
			<div id="grid-content--bid-{{model.id}}" class="grid-item--bid grid-item--no-grow grid-item--no-shrink text-center x-region--lot-actions">
			</div>
			{{#if bidding.hasBidding}}
				<div id="grid-content--autobid-{{model.id}}" class="grid-item--autobid grid-item--no-grow grid-item--no-shrink text-center x-region--auto-bid">
				</div>
			{{/if}}
		{{/if}}
	</div>
{{/if}}